import { PROFILE_TYPES } from '@/constants/profiles.const';

import NotificationChannel from '@Shared/legacy/NotificationChannel';

export class Profile {
  constructor(data) {
    this.uuid = data.uuid;
    this.type = data.type;
    this.alias = data.alias;
    this.email = data.email;
    this.notificationChannels = data.notifications
      && data.notifications.map(item => new NotificationChannel(item));
    this.mandatory_profile_cost_allocations_set = data.mandatory_profile_cost_allocations_set;

    // When profile is business, endpoint returns employee key
    // with data of this table
    this.employeeData = (() => {
      if (!!data.employee === false) {
        return null;
      }

      return {
        getLocation: () => data.employee.location || '',
        getStatus: () => data.employee.status,
      };
    })();

    // When profile is business, endpoint returns company key
    // with data of the company of the user
    this.companyData = (() => {
      if (!!data.company === false) {
        return null;
      }

      return {
        getUUID: () => data.company.uuid,
        getPhone: () => data.company.phone,
        getCompanyTariff: () => {
          if (data.company.allows_business_use_open) {
            if (!data.company.tariff.open) {
              return false;
            }
          }

          if (data.company.fleets && data.company.fleets.dedicated) {
            if (!data.company.tariff.dedicated) {
              return false;
            }
          }

          return true;
        },
        getCommercialName: () => data.company.commercial_name || '',
        getAddress: () => data.company.address || '',
        getDedicatedFleetCSOperatorUUID: () => data.company.dedicated_fleet_cs_operator,
        getCSOperatorUUID: () => data.company.cs_operator,
        hasOpenFleet: () => data.company.fleets.open,
        canUseCarpooling: () => !!data.company.allows_carpooling,
        isCostAllocationConfigurable: () => !!data.company.cost_allocation,
      };
    })();
  }

  getUUID() {
    return this.uuid;
  }

  getType() {
    return this.type;
  }

  getNotificationChannels() {
    return this.notificationChannels;
  }

  setNotificationChannels(notifications) {
    this.notificationChannels = notifications
      && notifications.map(item => new NotificationChannel(item));
  }

  setType(newType) {
    this.type = newType;
  }

  getAlias() {
    return this.alias;
  }

  setAlias(newAlias) {
    this.alias = newAlias;
  }

  getEmail() {
    return this.email;
  }

  getNotificationChannelFromType(type) {
    // TODO: Refactor this function. Returns Notification channel or NULL
    const empty = {
      value: '',
    };

    if (this.notificationChannels && this.notificationChannels.length) {
      const emailArray = this.notificationChannels.filter(item => item.getType() === type);
      return (emailArray.length) ? emailArray[0] : empty;
    }

    return empty;
  }

  getEmployeeData() {
    return this.employeeData;
  }

  getCompanyData() {
    return this.companyData;
  }

  isBusinessProfile() {
    return this.getType() === PROFILE_TYPES.business;
  }

  isPersonalProfile() {
    return this.getType() === PROFILE_TYPES.personal;
  }

  static create(attr) {
    return new Profile(attr);
  }
}

export default Profile;
